import { Injectable, inject } from '@angular/core';
import { BaseService } from '../../../../../../../shared/src/lib/services/base.service';
import { ICourse, ICourseSQ } from './courses.model';
import { ISR } from '../../../../../../../shared/src/lib/models/index.model';
import { map, of, tap } from 'rxjs';
import { EVFunctions, ICodeTitle } from 'ets-fe-ng-sdk';
import { Store } from '@ngrx/store';
import { CourseStore } from '../../../../../../../shared/src/lib/@ngrx/course/course.reducer';
import { uniqBy } from 'lodash-es';
import { DocumentService } from '../../../../../../../shared/src/lib/services/document.service';
import { ERefCat } from '../../../../../../../shared/src/lib/models/document.model';

@Injectable({
  providedIn: 'root',
})
export class CoursesService extends BaseService {
  protected override baseURL = `v1/cifm/`;
  protected store = inject(Store);
  protected documentService = inject(DocumentService);

  saveCourse(examCat: any, courseTitle: any) {
    return this.post<ICourse>({}, `courses${this.apiS.getRequestParse({ examCat, courseTitle })}`).pipe(
      tap(() => this.store.dispatch(CourseStore.actions.API.fetchAll())),
    );
  }

  updateCourse(id: number, data: ICourse) {
    return this.put<ICourse>(null, `courses/${id}${this.apiS.getRequestParse(data)}`).pipe(
      tap(() => this.store.dispatch(CourseStore.actions.API.fetchAll())),
    );
  }

  deleteCourse(id: string | number, dispatchUpdate = true) {
    return this.delete(`courses/${id}`).pipe(
      tap(() => (dispatchUpdate ? this.store.dispatch(CourseStore.actions.API.fetchAll()) : null)),
    );
  }

  searchCourses = (query?: ICourseSQ) => {
    return this.get<ISR<ICourse>>(`courses/search`, query).pipe(
      map((res) => {
        return {
          ...res,
          content: res.content.map((x) => ({ ...x, course: `${x.courseCode} - ${x.courseTitle}` })),
        };
      }),
    );
  };

  getCourseDocument = (courseCode: string) =>
    this.documentService.getDocumentAsArrayBuffer({ refNo: courseCode, refCat: ERefCat.course });

  listCoursesForInput = () =>
    this.searchCourses().pipe(
      map((res) => res.content.map<ICodeTitle>((c) => ({ code: c.courseCode, title: c.courseTitle }))),
    );

  searchCourses2 = (query?: ICourseSQ) => {
    return this.get<ISR<ICourse>>(`courses/search`, query).pipe(
      map((res) => uniqBy(res.content, (item) => item.courseCode)),
    );
  };

  fetchTitleByCode = (courseCode: string) =>
    courseCode
      ? this.getSingleCourse({ courseCode }).pipe(
          map((r) => EVFunctions.strConcatenator2(r.courseCode, r.courseTitle)),
        )
      : of('');

  getSingleCourse = (query?: ICourseSQ) => {
    return this.get<ISR<ICourse>>(`courses/search`, query).pipe(map((res) => res.content?.[0]));
  };
}
