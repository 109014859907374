import { createActionGroup, createReducer, createSelector, emptyProps, on, props } from '@ngrx/store';
import { IAppState } from '../index.state';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';

import { ICodeTitle } from 'ets-fe-ng-sdk';
import {
  ICourse,
  ICourseSQ,
} from '../../../../../admin/src/app/dashboard/intermediary-desk/cifm/courses/courses.model';
export namespace CourseStore {
  //#region STORE
  export interface IState extends EntityState<ICourse> {
    fetchedAll?: boolean;
  }

  const adapter: EntityAdapter<ICourse> = createEntityAdapter<ICourse>({
    sortComparer: (a, b) => a.courseCode?.localeCompare(b.courseCode),
    selectId: (i) => i.courseCode!,
  });

  const initialState: IState = adapter.getInitialState();
  //#endregion

  //#region ACTIONS
  export const actions = {
    API: createActionGroup({
      source: 'Course API',
      events: {
        fetchAll: emptyProps(),
        fetchAllIfEmpty: emptyProps(),
        fetchAllError: props<{ error: any }>(),
        fetchAllSuccess: props<{ result: ICourse[] }>(),
        fetchSingle: props<{ query: ICourseSQ }>(),
        fetchSingleError: props<{ error: any }>(),
        fetchSingleSuccess: props<{ result: ICourse }>(),
      },
    }),
  };
  //#endregion

  //#region SELECTORE
  export namespace selectors {
    const select = (state: IAppState) => state.course;

    
    export const selectAll = createSelector(select, adapter.getSelectors().selectAll);
    export const selectAllForInput = createSelector(
      selectAll,
      (list) =>
        list.map<ICodeTitle & { _og: ICourse }>((x) => ({
          code: x.courseCode,
          title: x.courseTitle,
          _og: x,
        })) || [],
    );
    export const selectAllMap = createSelector(select, adapter.getSelectors().selectEntities);

    export const selectByCode = (code: string) => createSelector(selectAllMap, (map) => map[code]);
  }
  //#endregion

  //#region REDUCER
  export const reducer = createReducer(
    initialState,
    on(actions.API.fetchAllSuccess, (state, { result: items }) => adapter.setAll(items, state)),
    on(actions.API.fetchAllSuccess, (state, { result: items }) => ({ ...state, fetchedAll: true })),
    on(actions.API.fetchSingleSuccess, (state, { result: item }) =>
      state.fetchedAll ? adapter.setOne(item, state) : state,
    ),
  );
  //#endregion
}
